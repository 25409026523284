<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <div class="flex-c-c">
        <el-input
          v-model="companyName"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入姓名或电话"
        ></el-input>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="getTableData"
          >查询</el-button
        >
      </div>
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="companyId" align="center" label="企业ID" />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column prop="name" align="center" label="管理员" />
        <el-table-column prop="phone" align="center" label="联系方式"/>
        <el-table-column prop="currentNum" align="center" label="名片数上限" />
        <el-table-column prop="allBusinessCardCount" align="center" label="企业员工数" />
        <el-table-column prop="createTimeA" align="center" label="开始时间" />
        <el-table-column prop="endTimeA" align="center" label="到期时长" />
        <el-table-column prop="endTime" align="center" label="状态">
          <template slot-scope="scope"
            ><span>
              {{scope.row | getStatus}}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope"
            ><span
              style="color: #51cbcd; cursor: pointer;margin-right:10px"
              @click="detail_tap(scope.row.companyId)"
            >
              详情
            </span>
            <span
                style="color: #51cbcd; cursor: pointer;margin-right:10px"
                @click="setSermissions(scope.row)"
            >
              设置
            </span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog title="权限管理" :visible.sync="dialogVisible" class="customer-manage-dialog" :modal-append-to-body="false">
      <el-form :model="setSermissionsform" label-width="120px" inline label-position="left">
        <el-form-item label="当前企业">
          <!-- <el-input v-model="setSermissionsform.companyName" auto-complete="off"></el-input> -->
          <div>{{setSermissionsform.companyName}}</div>
        </el-form-item>
        <br>
        <el-form-item label="管理员">
          <!-- <el-input v-model="setSermissionsform.administrator" auto-complete="off"></el-input> -->
          <div>{{setSermissionsform.administrator}}</div>
        </el-form-item>
        <br>
<!--        <el-form-item label="总购买数">-->
<!--          <el-input class="w240" v-model="setSermissionsform.purchaseNum" auto-complete="off"></el-input>-->
<!--        </el-form-item>-->
<!--        <br>-->
<!--        <el-form-item label="支付金额">-->
<!--          <el-input class="w240" v-model="setSermissionsform.payNum" auto-complete="off"></el-input>-->
<!--        </el-form-item>-->
<!--        <br>-->
        <el-form-item label="截止日期">
          <el-date-picker class="w240" v-model="setSermissionsform.time" :picker-options="pickerOptions"
                          type="date" format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <br>
<!--        <el-form-item label="购买版本">-->
<!--          <el-radio class="radio" v-model="setSermissionsform.companyType" :label="1">普通版</el-radio>-->
<!--          <el-radio class="radio" v-model="setSermissionsform.companyType" :label="2">OEM</el-radio>-->
<!--          <el-radio class="radio" v-model="setSermissionsform.companyType" :label="4">商协会</el-radio>-->
<!--        </el-form-item>-->
<!--        <br>-->
<!--        <el-form-item label="企业类型" v-if=" setSermissionsform.companyType != 4 ">-->
<!--          <el-radio class="radio" v-model="setSermissionsform.isAgent" :label="1">代理商</el-radio>-->
<!--          <el-radio class="radio" v-model="setSermissionsform.isAgent" :label="0">单企业</el-radio>-->
<!--        </el-form-item>-->
<!--        <template v-if="setSermissionsform.isAgent == 1">-->
<!--          <br>-->
<!--          <el-form-item :label="setSermissionsform.companyType == 1?'代理价格':'商协会价格'">-->
<!--            <el-input class="w240" v-model="setSermissionsform.agentMoney" auto-complete="off"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item :label="setSermissionsform.companyType == 1?'代理ID':'商协会价格'">-->
<!--            <el-input class="w240" disabled v-model="setSermissionsform.companyId" auto-complete="off">-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--        </template>-->
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  selectAgentCompanyList,addCompanyService
} from "@/api/agentManage.js";
import {
  getDataTimeSec
} from "@/utils/index.js";
import {
  str2date,
  getCookie
} from '@/utils/util.js';

export default {
  components: {
    commonTable,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      dialogVisible:false,
      companyName:'',
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      setSermissionsform: {
        companyName: '',
        administrator: '',
        companyId: '',
        purchaseNum: '',
        payNum: '',
        isAgent: '',
        companyType: '',
        time: ''
      }
    };
  },

  mounted() {
    this.getTableData();
  },
  filters:{
    getStatus(companyCard) {
      if (companyCard.endTime) {
        let endTime = new Date(companyCard.endTime).getTime();
        let nowTime = new Date().getTime();
        if (endTime > nowTime) {
          return "正常" // 未到期返回''
        } else {
          return "已到期"
        }
      } else {
        return ""
      }
    },
  },
  methods: {
    //请求预约数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyName: this.companyName
      };
      selectAgentCompanyList(data)
        .then((res) => {
          res.createTime = getDataTimeSec(res.createTime)
          res.endTime = getDataTimeSec(res.endTime)

          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.tableData.forEach(item => {
            item.createTimeA = getDataTimeSec(item.createTime)
            item.endTimeA = getDataTimeSec(item.endTime)
          });
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    //详情页
    detail_tap(companyId){
      this.$router.push('/companyDetail?id=' + companyId)
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    setSermissions(item) {
      let nowTime = str2date(new Date());
      let endTime = item.endTime ? str2date(item.endTime) : '';
      this.setSermissionsform = {
        companyName: item.companyName,
        administrator: item.name,
        companyId: item.companyId,
        purchaseNum: '',
        payNum: '',
        isAgent: item.isAgent,
        companyType: item.companyType,
        agentMoney: item.agentMoney ? (item.agentMoney / 100).toFixed(2) : '',
        time: nowTime > endTime ? nowTime : endTime
      };
      this.dialogVisible = true;
    },
    confirm() {
      let params = {
        companyId: this.setSermissionsform.companyId,
        // purchaseNum: this.setSermissionsform.purchaseNum,
        // payNum: (Number(this.setSermissionsform.payNum) * 100).toFixed(0),
        endTime: str2date(this.setSermissionsform.time),
        companyType: this.setSermissionsform.companyType, // 购买版本
        isAgent: this.setSermissionsform.companyType == 4 ? this.setSermissionsform.isAgent = 1:this.setSermissionsform.isAgent, // 企业类型
        agentMoney: this.setSermissionsform.isAgent == 1 ? (Number(this.setSermissionsform.agentMoney) *
            100).toFixed(0) : ''
      }
      addCompanyService(params).then(res => {
        if (res.code === 201) {
          this.$message({
            message: res.message,
            type: 'warning'
          });
          return;
        }
        this.dialogVisible = false;
        this.setSermissionsform = {};
        this.getTableData();
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.customer-manage-dialog {
  .el-dialog {
    width: 450px;

    .el-dialog__header {
      text-align: center;
    }

    .el-form-item {
      margin-bottom: 10px;
    }

    .w240 {
      width: 240px;
    }
  }
}
</style>
